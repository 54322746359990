import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'offerManagement',
    component: () => import(/* webpackChunkName: "OfferManagement" */ '../components/OfferManagement.vue')
  },
  {
    path: '/sku-management',
    name: 'skuManagement',
    component: () => import(/* webpackChunkName: "SKUManagement" */ '../components/SKUManagement.vue')
  },
  {
    path: '/ttop-generation',
    name: 'ttopGeneration',
    component: () => import(/* webpackChunkName: "TTOPGeneration" */ '../components/TTOPGeneration.vue')
  },
  {
    path: '/axis-table',
    name: 'axisTable',
    component: () => import(/* webpackChunkName: "AxisTable" */ '../components/AxisTable.vue')
  },
  {
    path: '/csv-compare',
    name: 'csvCompare',
    component: () => import(/* webpackChunkName: "CSVCompare" */ '../components/CSVCompare.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router